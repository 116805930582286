import React from 'react';
import Layout from '../../../../components/Layout';
import Seo from '../../../../components/SEO';
import SectionIntro from '../../../../components/SectionIntro';
import {useStaticQuery, graphql} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import {convertToBgImage} from 'gbimage-bridge';
import BannerRussel from '../../../../components/BannerRussel';
import BackGlossary from '../../../../components/BackGlossary';

const ZspZeroStandingPrivilege = () => {
  const dataImages = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);
  const {placeholderImage} = dataImages;
  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);
  const title = `LPA`;
  const description = `Least Privileged Access`;
  return (
    <Layout seo={<Seo title={`Glossary: ${title} | Trustle`} description={description} />}>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        className="bg-cover"
      >
        <section className="">
          <div className="flex flex-col pt-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto ">
            <SectionIntro isPageHeader hero="Glossary" title={title} description={`(${description})`} />
          </div>
        </section>

        <section className="pb-8">
          <div className="flex flex-col py-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto">
            <div className=" max-w-[1360px] 3xl:max-w-[1420px] mx-auto text-blue">
              <div className="py-16">
                <BackGlossary />
              </div>
              <div className="md:px-5 max-w-[1285px]">
                <div className="mb-8 text-xl font-bold text-left md:text-2xl">Least Privileged Access (LPA)</div>
                <p className="text-lg text-left">
                  Least privilege access (LPA) is the concept of giving a user exactly what they need to do their job
                  access permission-wise – nothing more, and nothing less.<br></br>
                  <br></br> <b>Why is LPA recommended?</b> <br></br>
                  <br></br>With LPA, if an identity is compromised, or if a legitimate user assuming the identity makes
                  a mistake while operating under the identity, the damage that can done with that identity is known and
                  controlled
                </p>
              </div>
            </div>
          </div>
        </section>
      </BackgroundImage>
      <section className="pb-8">
        <BannerRussel
          title="Wondering how Trustle works?"
          description={'Get an inside look at Trustle. Schedule a demo with us today.'}
        />
      </section>
    </Layout>
  );
};

export default ZspZeroStandingPrivilege;
